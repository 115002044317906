export default {

  bind(el, binding) {
    $(el).data('src', $(el).attr('src'));
    $(el).css({ cursor: 'pointer' });

    $(el).hover(
      () => {
        $(el).addClass('active');
        if (binding.value) {
          $(el).attr('src', binding.value);
        }
      },
      () => {
        $(el).removeClass('active');
        $(el).attr('src', $(el).data('src'));
      },
    );
  },
  componentUpdated(el, binding) {
    if (!($(el).hasClass('active'))) {
      $(el).data('src', $(el).attr('src'));
    }
    $(el).css({ cursor: 'pointer' });

    $(el).hover(
      () => {
        $(el).addClass('active');
        if (binding.value) {
          $(el).attr('src', binding.value);
        }
      },
      () => {
        $(el).removeClass('active');
        $(el).attr('src', $(el).data('src'));
      },
    );
  },
};
