import $ from 'jquery';

/**
 * Accept callback as a param. This callback will be executed
 * when animation finish.
 */
export default {
  bind(el, { value }) {
    if (value.disable) {
      return;
    }

    const callback = value.callback || (() => {
      // Nothing to do
    });
    const { element } = value;

    $(el).click(e => {
      e.preventDefault();

      $(`.${element}`).slideToggle('fast', callback);
    });
  },
};
