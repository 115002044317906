export default {
  bind(el, binding) {
    $(el).data('src', $(el).attr('src'));
    $(el).css({ cursor: 'pointer' });

    $(el).mousedown(() => {
      $(el).addClass('active');
      $(el).attr('src', binding.value);
    });

    if (binding.modifiers.mouseup) {
      $(el).mouseup(() => {
        $(el).removeClass('active');
        $(el).attr('src', $(el).data('src'));
      });
    }
  },
  componentUpdated(el, binding) {
    if (!($(el).hasClass('active'))) {
      $(el).data('src', $(el).attr('src'));
    }
    $(el).css({ cursor: 'pointer' });

    $(el).mousedown(() => {
      $(el).addClass('active');
      $(el).attr('src', binding.value);
    });

    if (binding.modifiers.mouseup) {
      $(el).mouseup(() => {
        $(el).removeClass('active');
        $(el).attr('src', $(el).data('src'));
      });
    }
  },
};
