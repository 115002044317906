import { useTranslations } from '@/composable/App/Translations';

export const getDurationLabels = () => {
  const { $t } = useTranslations();

  return {
    SINGULAR: {
      day: $t('common.format_days', { count: '' }, 1),
      hour: $t('common.format_hours', { count: '' }, 1),
      minute: $t('common.format_minutes', { count: '' }, 1),
    },
    PLURAL: {
      day: $t('common.format_days', { count: '' }, 2),
      hour: $t('common.format_hours', { count: '' }, 2),
      minute: $t('common.format_minutes', { count: '' }, 2),
    },
  };
};
