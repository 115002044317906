import { computed } from 'vue';
import {
  applyFilters,
  availabilityFilters,
  filtersAreCollapsed,
  getCollapsedFilters,
  getCollapsedFiltersApplied,
  getNotCollapsedFilters,
  isAvailabilityFilterApplied,
  isSomeAvailabilityFilterApplied,
  openAvailabilityFilter,
  openFiltersCollapsedPanel,
  openMoreFiltersPanel,
  resetAllSelectedFilters,
  resetSelectedFilters,
  setAvailabilityFilters,
  setAvailabilityFiltersDirectly,
  setFilterAreCollapsed,
  setNumFiltersAreCollapsed,
  setOldAvailabilityFilters,
} from '@Vehicle/store/Availability/AvailabilityMapper';

export const useAvailabilityFilter = () => {
  const currentAvailabilityFilter = computed(availabilityFilters);
  const areFiltersCollapsed = computed(filtersAreCollapsed);
  const areFiltersApplied = computed(isAvailabilityFilterApplied);
  const isSomeFiltersApplied = computed(isSomeAvailabilityFilterApplied);
  const isCollapsedFiltersOpen = computed(openFiltersCollapsedPanel);
  const collapsedFilters = computed(getCollapsedFilters);
  const notCollapsedFilters = computed(getNotCollapsedFilters);
  const collapsedFiltersApplied = computed(getCollapsedFiltersApplied);

  return {
    currentAvailabilityFilter,
    areFiltersCollapsed,
    areFiltersApplied,
    isSomeFiltersApplied,
    isCollapsedFiltersOpen,
    collapsedFilters,
    notCollapsedFilters,
    collapsedFiltersApplied,
    setAvailabilityFilters,
    setAvailabilityFiltersDirectly,
    openAvailabilityFilter,
    resetSelectedFilters,
    setOldAvailabilityFilters,
    setNumFiltersAreCollapsed,
    resetAllSelectedFilters,
    setFilterAreCollapsed,
    openMoreFiltersPanel,
    applyFilters,
  };
};
