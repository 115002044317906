import { scrollTo } from '@/utils/scrollTo';
const $ = require('jquery');

export default {
  update(el, binding, vnode) {
    if (!binding.value) {
      return;
    }

    vnode.context.$nextTick(() => {
      const elementPositionTop = $(el).position().top;
      const $app = $('#app');
      let margin = 0;

      $app.find('[data-outer-height]').each((_index, item) => {
        const $item = $(item);
        const itemPositionTop = $item.position().top;
        if (itemPositionTop < elementPositionTop) {
          const size = $(item).outerHeight();
          margin += parseInt(size, 10);
        }
      });

      const scrollPosition = elementPositionTop - margin;

      scrollTo(scrollPosition);
    });
  },
};
