export const DEFAULT_COORDINATES = {
  latitude: 40.427557,
  longitude: -3.699789,
};

export const MARKER_STATES = {
  geofence: 'geofence',
  default: 'default',
  hover: 'hover',
};
