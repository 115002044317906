import each from 'lodash/each';
import get from 'lodash/get';
import { useStore } from 'vuex-composition-helpers';
import { computed } from 'vue';

/**
 * A way to use Vuex store modules in a composable way.
 * It's like the old vuex mappers (mapState, mapMutation...) but all in once.
 * The modules has to be namespaced.
 * An example of mapStore:
 * {
 *  state: {
 *    test: state => state.test,
 *  },
 *  getters: ['testGetter'],
 *  mutations: ['testMutation'],
 *  actions: ['testAction'],
 * }
 * @param namespace
 * @param mapStore
 * @returns {}
 */
export const useStoreModule = (namespace, mapStore = {}) => {
  const {
    state = {},
    getters = [],
    mutations = [],
    actions = [],
  } = mapStore;
  const store = useStore();
  const normalizedNamespace = namespace.replace(/\//g, '.');
  const stateModule = get(store, `state.${normalizedNamespace}`);

  let stateToReturn = {};
  each(state, (returnStoreAttribute, keyName) => {
    stateToReturn = {
      ...stateToReturn,
      [keyName]: computed(() => returnStoreAttribute(stateModule)),
    };
  });

  let gettersToReturn = {};
  each(getters, getterName => {
    gettersToReturn = {
      ...gettersToReturn,
      [getterName]: computed(() => store.getters[`${namespace}/${getterName}`]),
    };
  });

  let mutationsToReturn = {};
  each(mutations, mutationName => {
    mutationsToReturn = {
      ...mutationsToReturn,
      [mutationName]: args => store.commit(`${namespace}/${mutationName}`, args),
    };
  });

  let actionsToReturn = {};
  each(actions, actionName => {
    actionsToReturn = {
      ...actionsToReturn,
      [actionName]: args => store.dispatch(`${namespace}/${actionName}`, args),
    };
  });

  return {
    ...stateToReturn,
    ...gettersToReturn,
    ...mutationsToReturn,
    ...actionsToReturn,
  };
};
