import get from 'lodash/get';
import each from 'lodash/each';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { computed } from 'vue';
import { City } from '@Shared/legacy/City';
import { getCurrentProfileUuid } from '@/stores/User/Profile/ProfileMapper';
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import {
  getCityCollection,
  getCityCollectionByProfile,
} from '@/stores/City/CityMapper';

// TODO: add test to this composable [CF-489]
export const useCity = () => {
  const cityCollectionByProfile = computed(getCityCollectionByProfile);
  const cityCollection = computed(getCityCollection);
  const currentProfileUuid = computed(getCurrentProfileUuid);

  const getCityAndDistanceMostClose = (lat, long, citiesCollection = []) => {
    const cities = citiesCollection;

    const google = googleMapsLoader.getInstance();

    const point = new google.maps.LatLng(lat, long);

    let cityMostClose = null;
    let distanceToClosestCity = null;

    each(cities, city => {
      const latitude = get(city, 'gpsLat', get(city, 'position.lat'));
      const longitude = get(city, 'gpsLng', get(city, 'position.long'));
      const cityCenter = new google.maps.LatLng(latitude, longitude);
      const distance = google.maps.geometry.spherical.computeDistanceBetween(point, cityCenter);
      if (isNil(distanceToClosestCity) || distance < distanceToClosestCity) {
        distanceToClosestCity = distance;
        cityMostClose = city;
      }
    });

    return {
      city: cityMostClose,
      distance: distanceToClosestCity,
    };
  };

  const getCityMostClose = async (lat, long) => {
    let cities = cityCollection.value(currentProfileUuid.value);
    // TODO Remove City object-values [https://europcarmobility.atlassian.net/browse/CF-480]
    cities = map(cities, cityItem => new City(cityItem));

    const { city } = getCityAndDistanceMostClose(lat, long, cities);

    return city;
  };

  const isLocationInSomeCity = async ({ lat, lng }) => {
    if (!(lat && lng)) {
      return null;
    }
    let cities = cityCollection.value(currentProfileUuid.value);
    // TODO Remove City object-values [https://europcarmobility.atlassian.net/browse/CF-480]
    cities = map(cities, city => new City(city));

    const {
      city: closestCity,
      distance,
    } = getCityAndDistanceMostClose(lat, lng, cities);

    // I'm using isNil() because distance could be 0
    if (isNil(closestCity) || isNil(distance)) {
      return null;
    }

    return distance <= closestCity.radius ? closestCity : null;
  };

  const getUnknownCity = (lat, lng) => new City({
    name: 'unknown',
    uuid: 0,
    gps_lat: lat,
    gps_lng: lng,
  });

  const isUnknownCity = (city = {}) => !city.uuid;

  const getCitiesBounds = (cities = []) => {
    const google = googleMapsLoader.getInstance();
    const cityBounds = new google.maps.LatLngBounds();
    const citiesByProfile = size(cities) ? cities : cityCollectionByProfile.value(currentProfileUuid.value);

    each(citiesByProfile, city => {
      const cityCoordinates = {
        lat: Number(city.getLatitude()),
        lng: Number(city.getLongitude()),
      };
      cityBounds.extend(cityCoordinates);
    });

    return cityBounds;
  };

  return {
    getCityAndDistanceMostClose,
    getCitiesBounds,
    isUnknownCity,
    getUnknownCity,
    isLocationInSomeCity,
    getCityMostClose,
  };
};
